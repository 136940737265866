@import "settings";

body.thank-you {
	.inside {
		padding:50px 0;
	}
}

.details-wrapper {
	background-color: #eaeaea;
    padding: 20px;
    width: 100%;
	margin-top:20px;
}

.google-map {
	position:relative;
	padding-bottom:65%;
	iframe {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		height:100%;
		width:100%;
	}
}

body {
	#wpmem_login .link-text, #wpmem_login .button_div, #wpmem_reg .button_div {
		text-align:left;
	}
	#wpmem_login input[type=checkbox] {
		width:auto;
		display:inline-block;
	}
	#wpmem_login .button_div {
		input[type="submit"] {
			margin-top: 25px;
		}
	}
	#wpmem_reg {
		@include clearfix;
		form {
			max-width:none;
		}
		.col-md-6 {
			float:left;
			width:50%;
		}
		.button_div {
			float:left;
			width:100%;
		}
		.req-text {
			float:left;
			width:100%;
		}
		input[type=radio] {
			width:auto;
			display:inline-block;
		}
	}
}

.main {
	form {
		max-width:800px;
		label {
			font-size:14px;
			font-weight:700;
		}
		input, textarea {
			border:1px solid $link-color;
			padding:10px 15px;
			display:block;
			transition:all 0.3s;
			width:100%;
			margin-top: 5px;
			&:focus {
				border-color:$light-blue-color;
			}
		}
		textarea {
			height:250px;
			min-height:250px;
			max-height:500px;
			min-width:100%;
			max-width:100%;
		}
		input[type="submit"] {
			width:auto;
			padding:15px 70px;
			font-size:18px;
			text-transform: uppercase;
			color:#fff;
			font-weight:700;
			background:$link-color;
			border:none;
			cursor:pointer;
			&:hover {
				background:$light-blue-color;
			}
		}
	}
}

img.aligncenter {
	margin:0 auto;
}
img.alignright {
	float:right;
	width:auto;
}
img.alignleft {
	float:left;
	width:auto;
}
img.alignnone {
	float:left;
	width:auto;
}
.news-posts.courses-posts {
	margin:20px 0;
}
table.courses {
	font-size:14px;
	display:block;
	border:2px solid #fff;
	tbody {
		display:block;
	}
	th {
		font-weight:700;
		background:$link-color;
		color:#fff;
		font-size:16px;
	}
	tr {
		display:block;
		@include clearfix;
		border-bottom:2px solid #fff;
		flex-wrap:wrap;
		display:flex;
		background-color:#bfeeff;
		&:nth-child(2n) {
			background:#dff6ff;
		}
		td, th {
			display:block;
			padding:30px 20px 20px 20px;
			float:left;
			border-right: 2px solid #fff;
			text-align:left;
			&:first-child {
				width:28%;
			}
			&:nth-child(2) {
				width:20%;
			}
			&:nth-child(3) {
				width:11%;
			}
			&:nth-child(4) {
				width:15%;
			}
			&:nth-child(5) {
				width:26%;
				border-right:none;
			}
		}
	}
	tr:last-child {
		border-bottom: none;
	}
}

header {
	position:relative;
	z-index:3;
	.top-bar {
		@include clearfix;
		.right {
			float:right;
			font-size:14px;
			margin-right: -8px;
			padding:22px 0;
			a {
				display:inline-block;
				padding:5px 8px;
				color:#666;
				&:hover {
					color:$font-color;
				}
			}
			a.become-member {
				float:left;
				background:$light-blue-color;
				color:#fff;
				padding:5px 8px;
				transition:all ease-out 0.3s;
				margin-right: 15px;
				&:hover {
					background:$link-color;
					color:#fff;
				}
			}
		}
	}
	.bottom-bar {
		@include clearfix;
		a.logo {
			float:left;
			transition:all 0.3s;
			display:block;
			&:hover {
				opacity:0.7;
			}
		}
		nav {
			float:right;
			margin-right: -17px;
			> ul {
				margin:0;
				padding:0;
				> li {
					display:block;
					float:left;
					margin:0 5px;
					position:relative;
					overflow:hidden;
					&.hidden {
						display:none;
					}
					&:after {
						content:"";
						display:block;
						position:absolute;
						left:0;
						right:0;
						bottom:-76px;
						height:76px;
						background:$light-blue-color;
						transition:all ease-out 0.3s;
						opacity:0;
					}
					&.current-menu-item {
						> a {
							color:#fff;
						}
						&:after {
							bottom:0;
							opacity:1;
						}
					}
					&:hover {
						> a {
							color:#fff;
						}
						&:after {
							bottom:0;
							opacity:1;
						}
					}
					> a {
						color:#666;
						display:block;
						height:76px;
						padding:0 12px;
						line-height:76px;
						transition:all ease-out 0.3s;
						position:relative;
						z-index:2;
					}
				}
			}
		}
	}
}

.hero {
	background:$link-color no-repeat center center;
	background-size:cover;
	padding:20rem 0 15rem 0;
	.overlay {
		color:#fff;
		text-align: center;
		font-size:28px;
		line-height:normal;
		.inside {
			max-width:880px;
			display:inline-block;
		}
		h1 {
			font-size:4.2rem;
			text-transform: uppercase;
			font-weight:700;
			position:relative;
			display:inline-block;
			margin:0;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:-10px;
				left:0;
				right:0;
				height:5px;
				background:#fff;
			}
		}
	}
}

.main {
	background:#fff;
	.introduction {
		padding:50px 0;
		@include clearfix;
		.left {
			float:left;
			width:60%;
			font-weight:300;
			color:#666;
			font-size:20px;
			float:none;
			width:auto;
		}
		.twitter-feed {
			float:right;
			width:35%;
			height:300px;
			background:$link-color;
		}
	}
	.featured {
		display:flex;
		flex-wrap:wrap;
		@include clearfix;
		margin:0 -11px;
		.align {
			position:absolute;
			bottom:25px;
			left:25px;
			right:25px;
			color:#fff;
			z-index:1;
			a {
				color:#fff;
			}
		}
		.inside {
			height:100%;
			width:100%;
			background:#000 no-repeat center center;
			background-size:cover;
			position:relative;
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:#000;
				opacity:0.4;
				transition:all 0.3s ease-out;
			}
		}
		.box-image {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				overflow:hidden;
				img {
					max-width:none;
					transition:transform 0.5s ease-in-out;
				}
			}
		}
		.box30:nth-child(5) {
			.inside {
				&:after {
					background:$dark-yellow-color;
					opacity:0.7;
				}
			}
			&:hover {
				.inside:after {
					opacity:0.9;
				}
			}
		}
		.box30 {
			float:left;
			padding:11px;
			width:33.33333%;
			min-height:400px;
		}
		.box70 {
			float:left;
			padding:11px;
			width:66.666666%;
			min-height:400px;
		}
		.box30:hover, .box70:hover {
			.inside {
				background-size:100% 100%;
				&:after {
					opacity:0.7;
				}
				.box-image {
					img {
						transform:scale(1.1,1.1);
					}
				}
			}
		}
		.category {
			display:inline-block;
			text-transform: uppercase;
			font-size:14px;
			background:$link-color;
			padding:5px;
			margin-bottom: 6px;
			&.cat-news {
				background:$light-blue-color;
			}
			&.cat-events {
				background:#00998c;
			}
			&.cat-courses {
				background:#142949;
			}
		}
		a.title {
			display:block;
			font-size:20px;
			font-weight:700;
			&:hover {
				text-decoration: underline;
			}
		}
		.content {
			margin-top: 20px;
			max-height: 132px;
    		overflow: hidden;
		}
	}
	.icon-columns {
		padding:10rem 0 7rem 0;
		@include clearfix;
		.col30 {
			text-align: center;
			float:left;
			width:33.3333%;
			padding:0 50px;
			font-size:15px;
			h2 {
				color:$dark-blue-color;
				font-size:20px;
			}
			.icn {
				height:85px;
				background:no-repeat top center;
			}
		}
	}
	&.single {
		article {
			margin-top: 50px;
		}
		.inside {
			img {
				max-height:300px;
			}
		}
		.event-details {
			margin-bottom: 20px;
		}
		&.single-events {
			.subpage-top {
				h2 {
					color:$teal-color;
				}
			}
		}
	}
}

.members-list {
	background:#f2f2f2;
	text-align: center;
	padding:8rem 0;
	h2 {
		color:$link-color;
		text-transform: uppercase;
	}
	img {
		display:inline-block;
		margin:20px 30px;
		opacity:0.7;
		-webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
		filter: grayscale(100%); /* FF 35+ */
		transition: all.5s ease-in-out;
		&:hover {
			-webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
	  		filter: grayscale(0%); /* FF 35+ */
			opacity:1;
		}
	}
	a {
		display:inline-block;
		margin-top: 50px;
		text-decoration: underline;
		color:$dark-blue-color;
		text-transform: uppercase;
		font-weight:700;
		&:hover {
			text-decoration:none;
		}
	}
}

.mailing-list {
	background:$light-blue-color;
	color:#fff;
	padding:50px 0;
	.inside {
		@include clearfix;
		h2 {
			float:left;
			width:20%;
			font-size:28px;
			margin:0;
			padding:0;
			text-transform: uppercase;
			font-weight:400;
		}
		form {
			float:left;
			width:80%;
			.input {
				float:left;
				width:36%;
				padding-right:30px;
				&:last-child {
					width:28%;
					padding-right: 0;
				}
			}
			input {
				float:left;
				width:100%;
				border:1px solid #fff;
				background:none;
				color:#fff;
				display:block;
				padding:8px 10px;
				transition:all 0.3s;
				margin-left: -1px;
				&:focus {
					border-color:$dark-blue-color;
				}
			}
			input::placeholder {
				color:#fff;
				text-transform: uppercase;
			}
			input[type="submit"] {
				width:100%;
				background:$dark-blue-color;
				text-transform: uppercase;
				cursor:pointer;
				&:hover {
					border-color:$link-color;
					background:$link-color;
				}
			}
			button{
				float:left;
				width:100%;
				border:1px solid #fff;
				background:none;
				color:#fff;
				display:block;
				padding:8px 10px;
				transition:all 0.3s;
				margin-left: -1px;
				width:100%;
				background:$dark-blue-color;
				text-transform: uppercase;
				cursor:pointer;
				&:hover {
					border-color:$link-color;
					background:$link-color;
				}
			}
		}
	}
}

footer {
	background:$dark-blue-color;
	color:#fff;
	a {
		color:#fff;
		&:hover {
			color:$light-blue-color;
		}
	}
	ul {
		margin-top: 10px;
		padding-left:0;
		li {
			position:relative;
			padding-left:20px;
			&:before {
				content:"";
				display:block;
				width:2px;
				height:2px;
				border-radius:100%;
				background:#fff;
				position:absolute;
				left:0;
				top:50%;
			}
		}
	}
	.top {
		padding:50px 0;
		@include clearfix;
		display:flex;
		flex-wrap:wrap;
	}
	.col25 {
		float:left;
		width:25%;
		padding:0 30px;
		border-right:1px solid #fff;
		font-size:14px;
		line-height:30px;
		&:first-child {
			padding-left:0;
		}
		&:last-child {
			border-right:none;
			padding-right:0;
		}
		h2 {
			font-size:20px;
			font-weight:400;
			color:$light-blue-color;
			text-transform: uppercase;
			margin:0;
		}
		p:last-child, ul:last-child {
			margin-bottom: 0;
		}
	}
	.bottom {
		color:#8c8c8c;
		text-align: center;
		font-size:13px;
		padding:0 0 20px 0;
		a {
			color:#8c8c8c;
			&:hover {
				color:#fff;
			}
		}
		ul {
			display:inline-block;
			li {
				margin-left: 20px;
				display:inline-block;
			}
		}
	}
}

.subpage-hero {
	background:$link-color no-repeat center center;
	background-size:cover;
	padding:10rem 0 10rem 0;
	.overlay {
		color:#fff;
		text-align: center;
		font-size:28px;
		line-height:normal;
		.inside {
			max-width:880px;
			display:inline-block;
		}
		h1 {
			font-size:4.2rem;
			text-transform: uppercase;
			font-weight:700;
			position:relative;
			display:inline-block;
			margin:0;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:-10px;
				left:0;
				right:0;
				height:5px;
				background:#fff;
			}
		}
	}
}

.column-content {
	@include clearfix;
	margin:0 -20px;
	.column {
		float:left;
		padding:20px;
	}
	.column50 {
		width:50%;
	}
	.column33 {
		padding:10px 35px;
		width:33.333%;
		font-size: 14px;
		font-weight: 300;
		line-height:26px;
	}
	.column25 {
		width:25%;
	}
	.column20 {
		width:20%;
	}
	.column66 {
		width: 66%;
	}
}

.main.subpage {
	padding-bottom: 10rem;
	&.has-sidebar {
		.inside {
			.subpage-content {
				float:left;
				width:60%;
			}
		}
		.subpage-explore {
			.left {
				.image {
					padding-bottom: 100%;
				}
			}
		}
		.news-overview {
			.news {
				width:50%;
			}
		}
		.event-overview {
			.event {
				width:50%;
			}
		}
	}
	.inside {
		@include clearfix;
	}
	h2,h3,h4,h5,h6 {
		color:$link-color;
		font-weight:400;
	}
	h2 {
		font-size:2.2em;
	}
	h3 {
		font-size:1.5em;
	}
	h4 {
		font-size:1.3em;
	}
	h5 {
		font-size:1.15em;
	}
	ul {
		li {
			line-height:30px;
		}
	}
}

.subpage-top {
	padding:20px 0 30px 0;
	border-bottom: 1px solid #ccc;
	margin-bottom: 30px;
	@include clearfix;
	.left {
		float:left;
		width:70%;
	}
	.right {
		font-size:14px;
		padding-top: 25px;
		float:right;
		text-transform: uppercase;
		.name {
			float:left;
		}
		.icons {
			margin:-3px 0 0 10px;
			float:left;
		}
		a {
			width: 27px !important;
			height:27px !important;
			border-radius:100%;
			background:#000;
			display:inline-block;
			transition:all 0.3s;
			position:relative;
			margin-left: 3px;
			&:hover {
				background:$link-color;
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				width:10px;
				height:10px;
			}
			&.fb:after {
				background:url(../images/facebook.png) no-repeat center center;
				background-size:cover;
			}
			&.tw:after {
				background:url(../images/twitter.png) no-repeat center center;
				background-size:cover;
			}
			&.ln:after {
				background:url(../images/linkedin.png) no-repeat center center;
				background-size:cover;
			}
			span, svg {
				display:none;
			}
		}
	}
	h2 {
		margin:0;
		font-weight:700 !important;
		text-transform: uppercase;
	}
}

.beauty-content {
	background:#f8f8f8;
	padding:50px 20px;
	border:1px solid $link-color;
	margin:30px 0;
	ul {
		list-style:none;
		li {
			position:relative;
			padding-left:30px;
			margin:0 0 25px 0;
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:7px;
				left:0;
				background:url(../images/arrow.png);
				width:10px;
				height:16px;
			}
		}
	}
}

.subpage-explore {
	@include clearfix;
	position:relative;
	margin:8rem 0;
	.left {
		position:absolute;
		top:0;
		left:0;
		width:70%;
		.image {
			height:0;
			padding-bottom:60%;
			position:relative;
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				overflow:hidden;
				img {
					max-width:none;
					transition:all 0.5s ease-in-out;
					&:hover {
						transform:scale(1.1,1.1);
					}
				}
			}
		}
	}
	.right {
		float:right;
		width:50%;
		margin-top: 100px;
		background:$link-color;
		color:#fff;
		padding:80px 40px 40px 40px;
		position:relative;
		z-index:2;
		font-weight:300;
		h2,h3,h4,h5,h6 {
			color:#fff !important;
		}
		a.btn {
			text-transform: none;
			border:1px solid #fff;
			padding:10px 40px;
			&:hover {
				background:#fff;
				color:$link-color;
			}
		}
	}
}

.event-list {
	.row {
		background-color: #f7f7f7;
		display:block;
		padding:15px 50px 15px 15px;
		position:relative;
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:50%;
			transform:translateY(-50%);
			right:20px;
			background:url(../images/arrow.png);
			width:10px;
			height:16px;
			transition:transform 0.3s ease-out;
		}
		&:hover {
			&:after {
				background:url(../images/arrow-blue.png);
				transform:translateY(-50%) translateX(5px);
			}
			.title {
				color:$light-blue-color;
			}
		}
		.date {
			display:block;
			font-size:14px;
			color:#8e8e8e;
			margin-bottom: 10px;
		}
		.title {
			display:block;
			color:$font-color;
			transition:all 0.3s;
		}
		&:nth-child(2n) {
			background:#fff;
		}
	}
}

.accordition {
	margin:30px 0 50px 0;
	.accord {
		margin-bottom: 3px;
		.title {
			color:#fff;
			background:$light-blue-color;
			@include clearfix;
			position:relative;
			padding:15px;
			transition:all 0.3s;
			cursor:pointer;
			&.active {
				background:$link-color;
			}
			.name {
				padding-right:50px;
			}
			.sign {
				position:absolute;
				top:50%;
				right:20px;
				font-weight:700;
				font-size:24px;
				transform:translateY(-17px);
			}
		}
		.content {
			display:none;
			padding:20px 10px;
		}
	}
}

.news-overview {
	@include clearfix;
	display:flex;
	flex-wrap:wrap;
	margin:0 -30px;
	.news {
		float:left;
		width:33.33333%;
		padding:30px;
		a.title {
			color:$light-blue-color;
			font-weight:400;
			display:block;
			font-size:24px;
			&:hover {
				color:$dark-blue-color;
			}
		}
		.date {
			font-size:14px;
			color:#8e8e8e;
			margin-bottom: 10px;
		}
		.content {
			margin-top: 25px;
		}
	}
}

.event-overview {
	@include clearfix;
	display:flex;
	flex-wrap:wrap;
	margin:0 -30px;
	.event {
		float:left;
		width:33.33333%;
		padding:30px;
		a.title {
			color:#00998c;
			font-weight:400;
			display:block;
			font-size:24px;
			&:hover {
				color:$link-color;
			}
		}
		.content {
			margin-top: 25px;
			display:table;
			.row {
				display:table-row;
				.left {
					width:120px;
					font-weight:700;
				}
				.left, .right {
					display:table-cell;
					padding:5px 0;
				}
			}
		}
	}
}

.sidebar {
	float:right;
	width:30%;
	padding-left:50px;
	font-size:15px;
	margin-top: 30px;
	.box {
		margin-bottom: 50px;
		border-bottom: 3px solid $link-color;
		.title {
			padding:10px;
			background:$link-color;
			color:#fff;
			text-transform: uppercase;
		}
		ul {
			list-style:none;
			margin:12px 0;
			padding:0;
			li {
				line-height:35px;
				&.current_page_item {
					a {
						color:$link-color;
						font-weight:700;
					}
				}
			}
			a {
				color:#666666;
				&:hover {
					color:$link-color;
					text-decoration: underline;
				}
			}
		}
	}
}

.buttons {
	a.btn {
		&:first-child {
			margin-left: 0;
		}
	}
}

.news-posts {
	@include clearfix;
	margin:20px -10px;
	.news-post {
		float:left;
		width:33.3333%;
		padding:10px;
		margin-bottom: 40px;
		&:nth-child(3n+1) {
			clear:left;
		}
		.news-image {
			position:relative;
			height:0;
			padding-bottom:50%;
			overflow:hidden;
			.img-resize {
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				transition:transform 0.5s ease-in-out;
				&:hover {
					transform:scale(1.1,1.1);
				}
				img {
					max-width:none;
				}
			}
		}
		a.news-title {
			display:block;
			color:$light-blue-color;
			font-size:24px;
			&:hover {
				color:$link-color;
			}
		}
		.news-date {
			font-weight:300;
			color:#999999;
			margin-bottom: 25px;
			font-size:12px;
		}
		.news-excerpt {
			font-size:14px;
		}
	}
	&.events-posts {
		.news-post {
			a.news-title {
				color:$teal-color;
				margin-bottom: 15px;
				&:hover {
					color:$link-color;
				}
			}
		}
	}
}
.event-details {
	display:table;
	margin-top: 15px;
	.row {
		display:table-row;
		font-size:14px;
		.left, .right {
			display:table-cell;
			padding:5px;
		}
		.left {
			font-weight:700;
			padding-left:0;
		}
	}
}

.pagination {
	text-align:center;
	margin-top: 35px;
	@include clearfix;
	a, span {
		display: inline-block;
		border: 1px solid #c5c5c5;
		height: 29px;
		line-height: 29px;
		padding: 0 7px;
		color: #333;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 700;
		margin:0 3px;
	}
	span {
		border-color:transparent;
	}
	a:hover {
		border-color:$light-blue-color;
		color:$light-blue-color;
		transition:all 0.3s;
	}
	a.prev, a.next {
		background-color:$light-blue-color;
		color:#fff;
		border-color:$light-blue-color;
		&:hover {
			background-color:$link-color;
			border-color:$link-color;
		}
	}
}

@media only screen and (max-width:1200px) {
	header {
		.bottom-bar {
			nav {
				margin-right: -7px;
				> ul {
					> li {
						margin:0;
						> a {
							padding:0 7px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width:1000px) {
	html {
		font-size:40%;
	}
	header {
		.top-bar {
			display:none;
		}
		.bottom-bar {
			padding-top:30px;
			a.logo {
				margin-bottom: 23px;
			}
			.responsive-nav {
				display:block;
			}
			nav {
				position:absolute;
				top:101px;
				left:0;
				right:0;
				z-index:3;
				background:$link-color;
				opacity:0;
				visibility: hidden;
				transform:translateY(50px);
				transition:all 0.5s ease-out;
				&.active {
					opacity:1;
					visibility: visible;
					transform:translateY(0);
				}
				> ul {
					> li {
						display:block;
						float:none;
						border-bottom: 1px solid $dark-blue-color;
						text-align: center;
						&.hidden {
							display:block;
						}
						&.highlighted {
							a {
								background-color:$light-blue-color;
							}
						}
						&:after {
							display:none;
						}
						> a {
							color:#fff;
							height:auto;
							line-height:normal;
							padding:14px;
							&:hover {
								background:$light-blue-color;
							}
						}
					}
				}
			}
		}
	}
	.hero {
		.overlay {
			font-size:18px;
		}
	}
	.main {
		&.subpage.has-sidebar {
			.inside {
				.subpage-content {
					width:100%;
				}
				.sidebar {
					padding-left: 0;
					margin-top: 50px;
					width:100%;
				}
			}
		}
		.featured {
			.box30, .box70 {
				width:50%;
				&:last-child {
					width:100%;
				}
			}
		}
		.icon-columns {
			.col30 {
				padding:0 10px;
			}
		}
	}
	.mailing-list {
		.inside {
			h2 {
				width:100%;
			}
			form {
				width:100%;
			}
		}
	}
	footer {
		.col25 {
			width:50%;
			border-right:none;
			margin-bottom: 30px;
			&:nth-child(3) {
				padding-left: 0;
			}
		}
	}
}

@media only screen and (max-width:800px) {
	.pageAlign {
		padding:0 10px;
	}
	a.btn {
		font-size:16px;
	}
	.hero {
		a.btn {
			margin: 5px 0;
		}
	}
	.main {
		.introduction {
			.left {
				width:100%;
			}
			.twitter-feed {
				width:100%;
			}
		}
	}
	.column-content {
		.column33, .column66, .column50 {
			width:100% !important;
		}
	}
	.news-overview {
		.news {
			width:50%;
		}
	}
	.event-overview {
		.event {
			width:50%;
		}
	}
}

@media only screen and (max-width:600px) {
	header {
		.bottom-bar {
			a.logo {
				img {
					width:240px;
				}
			}
			nav {
				top:83px;
			}
		}
	}
	.main {
		.introduction {
			.left {
				font-size:16px;
			}
		}
		.featured {
			margin:0;
			.box30, .box70 {
				width:100%;
				padding:5px 0;
			}
		}
		.icon-columns {
			padding:30px 0;
			.col30 {
				width:100%;
				padding:0;
				margin:20px 0;
			}
		}
	}
	.members-list {
		img {
			max-width:80px;
			max-height:60px;
		}
	}
	.mailing-list {
		text-align: center;
		.inside {
			h2 {
				margin-bottom: 30px;
			}
			form {
				.input {
					width:100%;
					padding-right: 0;
					margin-bottom: 5px;
					&:last-child {
						width:100%;
					}
				}
				input {
					text-align: center;
				}
			}
		}
	}
	footer {
		text-align: center;
		.col25 {
			width:100%;
			padding:0;
		}
		.top {
			ul {
				li:before {
					display:none;
				}
			}
		}
		.bottom {
			ul {
				li {
					margin-left: 10px;
					padding-left: 10px;
				}
			}
		}
	}
	.subpage-explore {
		.left {
			position:static;
			width:auto;
		}
		.right {
			margin-top: 0;
			width:100%;
		}
	}
	.column-content {
		.column25 {
			width:100% !important;
		}
	}
	.news-overview {
		.news {
			width:100%;
		}
	}
	.event-overview {
		.event {
			width:100%;
		}
	}
}
